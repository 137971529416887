import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import {
    sendPasswordResetEmail,
  } from "firebase/auth";
import "./ResetPasswordScreen.css";
import Copyright from "../../components/Footer/Copyright";
import { auth } from "../../firebase.js";
import { Link } from "react-router-dom";
import GoToTop from '../../GoToTop';
/* import {Helmet} from "react-helmet"; */
/* import Head from "next/head"; */
import logo from "../../assets/Redground-oldlogo.png";

function ResetPasswordScreen() {

    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const navigate = useNavigate();

    const forgotPassword = (emailRef) => {
        return sendPasswordResetEmail(auth, emailRef);
      };

    const forgotPasswordHandler = () => {
        const email = emailRef.current.value;
        if (email)
          forgotPassword(email).then(() => {
            emailRef.current.value = "";
          });
      };

    const gotoHome = (e) => {
        e.preventDefault();
        navigate("/");
    }

  return (
    <main>

        {/* <Head>
            <title>Cambio de password : REDGROUND</title>
            <meta 
            name='description'
            content=""
            />
        </Head>

        <Helmet>
            <title>Cambio de password : REDGROUND</title>
            <meta 
            name='description'
            content=""
            />
            <meta 
            name='image' 
            content="https://firebasestorage.googleapis.com/v0/b/demoqrata.appspot.com/o/resources%2Fdemoqrata_icons_background.webp?alt=media&token=996472e3-59df-4972-91a2-b201329d009c"
            />
        </Helmet> */}

        {/* <NavProfile /> */}

        <header className="banner"
            style={{
                backgroundSize: "cover",
                backgroundImage: `linear-gradient(to top, black 5%, transparent 30%), linear-gradient(to top, #00001b, transparent), 
                     url(
                    "https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcover%2Fmars_cover.webp?alt=media&token=e91c8978-6026-446d-886f-b41574632be4&_gl=1*8nae7i*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODE4NzQyMS4xLjEuMTY5ODE4NzYwMi40Mi4wLjA."
                )`, 
                backgroundPosition: "center center",
            }}>

            <>
            <div className="resetpassword">

            <div className="resetpassword__container">
                <div className="resetpassword__msg">
                    <p>Cambia tu password</p>
                    <img
                        src={logo}
                        alt="redground"
                        onClick={gotoHome}
                    />
                </div>

                <form >
                    <input  ref={emailRef} type="email" placeholder="email" id="email" />

                    <button 
                    className="resetpassword__button"
                    type="submit" onClick={forgotPasswordHandler}>
                        Enviar solicitud
                    </button>
                </form>

                <div className="resetpassword__help">
                    <p>Te enviaremos un enlace para cambiar tu password, por favor revisa tu bandeja de correo. Busca en tu bandeja de spam por si no lo encuentras.</p>
                    <div className="resetpassword__help__signup">
                        <p >¿Ya tienes password?</p>
                        <Link to="/signin">
                        <span>Inicia sesión</span>
                        </Link>
                    </div>

                    <div className="resetpassword__help__signup">
                        <p >¿Aún no tienes cuenta?</p>
                        <Link to="/signup">
                        <span >Crea tu cuenta</span>
                        </Link>
                    </div>
                </div>

            </div>
            </div>
            </>
        </header>

        {/* <Social />
        <FooterLinks /> */}
        <Copyright />
        <GoToTop />
    </main>
  )
}

export default ResetPasswordScreen;