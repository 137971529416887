import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import firebaseApp from "./firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { CadastreArrayContext } from './context/CadastreArrayContext';
import HomeScreen from './screens/HomeScreen';
import DocScreen from './screens/DocScreen';
import SignInScreen from './screens/landing/SignInScreen';
import ResetScreen from './screens/landing/ResetPasswordScreen';
import NewUserScreen from './screens/landing/NewUserScreen';
import TokenScreen from './screens/TokenScreen';
import preload from "./assets/Mars-loader.gif";

function App() {
  const [usuarioGlobal, setUsuarioGlobal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cadastreArray, setCadastreArray] = useState([]);

  useEffect(() => {
    const auth = getAuth(firebaseApp);
    const unsubscribe = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase) {
        setUsuarioGlobal(usuarioFirebase);
      } else {
        setUsuarioGlobal(null);
      }
    });

    return () => {
      unsubscribe(); // Cleanup the listener when the component unmounts
    };
  }, []);


  return (
    <CadastreArrayContext.Provider value={[cadastreArray, setCadastreArray]}>
    <div className="app">

    {isLoading
      ? (
      <Router onUpdate={() => window.scrollTo(0, 0)}>
        {!usuarioGlobal
          ? (
          <Routes>
            <Route exact path="/" element={<SignInScreen />} />
            <Route exact path="/signup" element={<NewUserScreen />} />
            <Route exact path="/reset" element={<ResetScreen />} />
          </Routes>
          )
          : (
          <Routes>
            <Route
              exact
              path="/"
              element={<Navigate to="/mars" />}
            />
            <Route path="/mars" element={<HomeScreen />} />
            <Route path="/token" element={<TokenScreen />} />
            <Route exact path="/doc" element={<DocScreen />} />
          </Routes>
          )
        }
      </Router>
      )
      :(
      <div className="app__preload">
        <img
          className="app__preload__img"
          src={preload}
          alt=""
        />
      </div>
      )
    }
    </div>
    </CadastreArrayContext.Provider>
  );
}

export default App;