export const cadastreAll = [
	{
		"id":1,
		"cod":"MC-01",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F01-Mars-North-cadastre-1.webp?alt=media&token=66a83acb-eee7-43e4-99a7-4d8d5393d019&_gl=1*19f6dg7*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkxNi42MC4wLjA.",
		"hemisphere":"North",
		"title":"MC-01 Mare Boreum",
		"internal":"Borealis, Planum Boreumm, Planum Septentrionale",
		"parcels":"Borealis Basin, Borealis Planum, Planum Boreum Impact Basin, Planum Septentrionale Unit",
		"description":"The Borealis internal zone is a large region in the northern hemisphere of Mars that is characterized by its smooth, low-lying surface. The Planum Boreum internal zone is a region in the northern hemisphere of Mars that is characterized by its high-latitude plains. The Planum Septentrionale internal zone is a region in the northern hemisphere of Mars that is characterized by its low-latitude plains.",
		"details":"The Borealis Basin is a large impact basin in the northern hemisphere of Mars. The Borealis Planum is a high-latitude plain in the northern hemisphere of Mars. The Planum Boreum Impact Basin is a large impact basin in the northern hemisphere of Mars. The Planum Septentrionale Unit is a low-latitude plain in the northern hemisphere of Mars."
	},
	{
		"id":2,
		"cod":"MC-02",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F02-Mars-North-cadastre-1.webp?alt=media&token=f88128b2-6d47-4337-adc9-d95324441d7d&_gl=1*59g4h6*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkyMi41NC4wLjA.",
		"hemisphere":"North",
		"title":"MC-02 Diacria",
		"internal":"Diacria Basin, Diacria Planum, Diacria Montes",
		"parcels":"Diacria Basin Unit, Diacria Planum Unit, Diacria Montes Unit",
		"description":"The Diacria Basin internal zone is a large impact basin in the northern hemisphere of Mars. The Diacria Planum internal zone is a region in the northern hemisphere of Mars that is characterized by its high-latitude plains. The Diacria Montes internal zone is a region in the northern hemisphere of Mars that is characterized by its mountains.",
		"details":"The Diacria Basin Unit is a large impact basin in the northern hemisphere of Mars. The Diacria Planum Unit is a high-latitude plain in the northern hemisphere of Mars. The Diacria Montes Unit is a mountains unit in the northern hemisphere of Mars."
	},
	{
		"id":3,
		"cod":"MC-03",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F03-Mars-North-cadastre-1.webp?alt=media&token=a11e3294-78c0-4afd-92e7-382453f184f9&_gl=1*1tgcddl*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkyNi41MC4wLjA.",
		"hemisphere":"North",
		"title":"MC-03 Arcadia",
		"internal":"Arcadia Planitia, Arcadia Patera, Tharsis Montes",
		"parcels":"Arcadia Planitia Unit, Arcadia Patera Unit, Tharsis Montes Unit",
		"description":"Arcadia Planitia is a large plain in the Tharsis region of Mars. Arcadia Patera is a large volcanic caldera in the Tharsis region of Mars. Tharsis Montes is a mountain range in the Tharsis region of Mars.",
		"details":"The Arcadia Planitia Unit is a unit within the Arcadia Planitia internal zone that is characterized by its smooth, low-lying surface. The Arcadia Patera Unit is a unit within the Arcadia Patera internal zone that is characterized by its volcanic caldera. The Tharsis Montes Unit is a unit within the Tharsis Montes internal zone that is characterized by its mountains."
	},
	{
		"id":4,
		"cod":"MC-04",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F04-Mars-North-cadastre-1.webp?alt=media&token=8bf6e5cc-1c32-4721-9bc3-1a0c8a07586e&_gl=1*1ez50va*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkzMC40Ni4wLjA.",
		"hemisphere":"North",
		"title":"MC-04 Acidalium",
		"internal":"Acidalia Planitia, Protonilus Mensa, Deuteronilus Mensa",
		"parcels":"Acidalia Planitia Unit, Protonilus Mensa Unit, Deuteronilus Mensa Unit",
		"description":"Acidalia Planitia is a large plain in the northern hemisphere of Mars. Protonilus Mensa is a plateau in the northern hemisphere of Mars. Deuteronilus Mensa is a plateau in the northern hemisphere of Mars.",
		"details":"The Acidalia Planitia Unit is a unit within the Acidalia Planitia internal zone that is characterized by its smooth, low-lying surface. The Protonilus Mensa Unit is a unit within the Protonilus Mensa internal zone that is characterized by its plateau. The Deuteronilus Mensa Unit is a unit within the Deuteronilus Mensa internal zone that is characterized by its plateau."
	},
	{
		"id":5,
		"cod":"MC-05",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F05-Mars-North-cadastre-1.webp?alt=media&token=96e393d6-3e52-4314-88ea-ee7795e98609&_gl=1*ltq4s5*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkzNC40Mi4wLjA.",
		"hemisphere":"North",
		"title":"MC-05 Ismenius Lacus",
		"internal":"Ismenius Lacus, Syria Planum, Amenthes Planitia",
		"parcels":"Ismenius Lacus Unit, Syria Planum Unit, Amenthes Planitia Unit",
		"description":"Ismenius Lacus is a large plain in the northern hemisphere of Mars. Syria Planum is a plain in the northern hemisphere of Mars. Amenthes Planitia is a plain in the northern hemisphere of Mars.",
		"details":"The Ismenius Lacus Unit is a unit within the Ismenius Lacus internal zone that is characterized by its smooth, low-lying surface. The Syria Planum Unit is a unit within the Syria Planum internal zone that is characterized by its smooth, low-lying surface. The Amenthes Planitia Unit is a unit within the Amenthes Planitia internal zone that is characterized by its smooth, low-lying surface."
	},
	{
		"id":6,
		"cod":"MC-06",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F06-Mars-North-cadastre-1.webp?alt=media&token=f6710eff-88d1-4ff9-b7fb-706540883108&_gl=1*1evemek*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkzOC4zOC4wLjA.",
		"hemisphere":"North",
		"title":"MC-06 Casius",
		"internal":"Casius Mons, Casius Planum, Amazonis Planitia",
		"parcels":"Casius Mons Unit, Casius Planum Unit, Amazonis Planitia Unit",
		"description":"Casius Mons is a volcano in the northern hemisphere of Mars. Casius Planum is a plain in the northern hemisphere of Mars. Amazonis Planitia is a plain in the northern hemisphere of Mars.",
		"details":"The Casius Mons Unit is a unit within the Casius Mons internal zone that is characterized by its volcanic features. The Casius Planum Unit is a unit within the Casius Planum internal zone that is characterized by its smooth, low-lying surface. The Amazonis Planitia Unit is a unit within the Amazonis Planitia internal zone that is characterized by its smooth, low-lying surface."
	},
	{
		"id":7,
		"cod":"MC-07",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F07-Mars-North-cadastre-1.webp?alt=media&token=5e5c4539-2062-4f7d-aa9d-9673c96ca8fb&_gl=1*w89j5f*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDk0Mi4zNC4wLjA.",
		"hemisphere":"North",
		"title":"MC-07 Cebrenia",
		"internal":"Cebrenia, Utopia Planitia, Arcadia Planitia",
		"parcels":"Cebrenia Unit, Utopia Planitia Unit, Arcadia Planitia Unit",
		"description":"A region in the northern hemisphere of Mars that is characterized by its low-lying plains and impact craters. Utopia Planitia: A vast, lowland plain in the northern hemisphere of Mars. It is located to the east of the Tharsis Montes volcanic region, and is home to three large volcanoes: Elysium Mons, Hecates Tholus, and Albor Tholus. Arcadia Planitia: A large plain in the Tharsis region of Mars, located to the east of Cebrenia and west of Isidis Planitia. It is home to three large volcanoes: Elysium Mons, Hecates Tholus, and Albor Tholus.",
		"details":"A unit within the Cebrenia internal zone that is characterized by its low-lying plains and impact craters. Utopia Planitia Unit: A unit within the Utopia Planitia internal zone that is characterized by its smooth, low-lying surface. Arcadia Planitia Unit: A unit within the Arcadia Planitia internal zone that is characterized by its smooth, low-lying surface."
	},
	{
		"id":8,
		"cod":"MC-08",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F08-Mars-North-cadastre-1.webp?alt=media&token=96c1c9e0-2f46-4078-a4d0-ef1aa329bda3&_gl=1*136njfk*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDk0Ni4zMC4wLjA.",
		"hemisphere":"North",
		"title":"MC-08 Amazonis",
		"internal":"Amazonis Planitia, Chryse Planitia, Acidalia Planitia",
		"parcels":"Amazonis Planitia Unit, Chryse Planitia Unit, Acidalia Planitia Unit, Chryse Basin Unit, Aram Chaos Unit, Valles Marineris Unit",
		"description":"Amazonis Planitia is a large plain in the northern hemisphere of Mars. Chryse Planitia is a plain in the northern hemisphere of Mars. Acidalia Planitia is a plain in the northern hemisphere of Mars. Chryse Basin is a large impact basin in the northern hemisphere of Mars. Aram Chaos is a chaotic terrain region in the northern hemisphere of Mars. Valles Marineris is a system of canyons in the western hemisphere of Mars.",
		"details":"The Amazonis Planitia Unit is a unit within the Amazonis Planitia internal zone that is characterized by its smooth, low-lying surface. The Chryse Planitia Unit is a unit within the Chryse Planitia internal zone that is characterized by its smooth, low-lying surface. The Acidalia Planitia Unit is a unit within the Acidalia Planitia internal zone that is characterized by its smooth, low-lying surface. The Chryse Basin Unit is a unit within the Chryse Basin internal zone that is characterized by its large impact basin. The Aram Chaos Unit is a unit within the Aram Chaos internal zone that is characterized by its chaotic terrain. The Valles Marineris Unit is a unit within the Valles Marineris internal zone that is characterized by its system of canyons."
	},
	{
		"id":9,
		"cod":"MC-09",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F09-Mars-North-cadastre-2.webp?alt=media&token=b26f2257-ce2a-4c7c-a2a1-3a28e674f068&_gl=1*1xtjobl*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA2Mi41Ni4wLjA.",
		"hemisphere":"North",
		"title":"MC-09 Tharsis",
		"internal":"Tharsis Montes, Tharsis Planitia, Noctis Labyrinthus",
		"parcels":"Tharsis Montes Unit, Tharsis Planitia Unit, Noctis Labyrinthus Unit, Arsia Mons Unit, Pavonis Mons Unit, Olympus Mons Unit, Elysium Planitia Unit, Cerberus Fossae Unit",
		"description":"Tharsis Montes is a mountain range in the Tharsis region of Mars. Tharsis Planitia is a plain in the Tharsis region of Mars. Noctis Labyrinthus is a chaotic terrain region in the Tharsis region of Mars. Arsia Mons is a volcano in the Tharsis region of Mars. Pavonis Mons is a volcano in the Tharsis region of Mars. Olympus Mons is a volcano in the Tharsis region of Mars and the tallest mountain in the solar system. Elysium Planitia is a plain in the Elysium region of Mars. Cerberus Fossae is a system of fissures in the Elysium region of Mars.",
		"details":"The Tharsis Montes Unit is a unit within the Tharsis Montes internal zone that is characterized by its mountains. The Tharsis Planitia Unit is a unit within the Tharsis Planitia internal zone that is characterized by its plain. The Noctis Labyrinthus Unit is a unit within the Noctis Labyrinthus internal zone that is characterized by its chaotic terrain. The Arsia Mons Unit is a unit within the Arsia Mons internal zone that is characterized by its volcano. The Pavonis Mons Unit is a unit within the Pavonis Mons internal zone that is characterized by its volcano. The Olympus Mons Unit is a unit within the Olympus Mons internal zone that is characterized by its volcano. The Elysium Planitia Unit is a unit within the Elysium Planitia internal zone that is characterized by its plain. The Cerberus Fossae Unit is a unit within the Cerberus Fossae internal zone that is characterized by its fissures."
	},
	{
		"id":10,
		"cod":"MC-10",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F10-Mars-North-cadastre-2.webp?alt=media&token=1756ba0f-a182-4bd7-85e0-7453a4fad2c5&_gl=1*18hm2w5*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA2Ny41MS4wLjA.",
		"hemisphere":"North",
		"title":"MC-10 Lunae Palus",
		"internal":"Lunae Palus, Xanthe Terra, Chryse Planitia",
		"parcels":"Lunae Palus Unit, Xanthe Terra Unit, Chryse Planitia Unit, Lunae Palus North Unit, Lunae Palus South Unit",
		"description":"Lunae Palus is a volcanic plateau in the northern hemisphere of Mars. Xanthe Terra is a highland region in the northern hemisphere of Mars. Chryse Planitia is a plain in the northern hemisphere of Mars. The Lunae Palus Unit is a unit within the Lunae Palus internal zone that is characterized by its smooth, low-lying surface. The Xanthe Terra Unit is a unit within the Xanthe Terra internal zone that is characterized by its highland features. The Chryse Planitia Unit is a unit within the Chryse Planitia internal zone that is characterized by its smooth, low-lying surface.",
		"details":"The Lunae Palus North Unit is a unit within the Lunae Palus internal zone that is characterized by its smooth, low-lying surface. The Lunae Palus South Unit is a unit within the Lunae Palus internal zone that is characterized by its smooth, low-lying surface."
	},
	{
		"id":11,
		"cod":"MC-11",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F11-Mars-North-cadastre-2.webp?alt=media&token=2691b21c-836b-447d-82c2-be208986875d&_gl=1*17dkbjl*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA3MS40Ny4wLjA.",
		"hemisphere":"North",
		"title":"MC-11 Oxia Palus",
		"internal":"Oxia Palus, Arabia Terra, Chryse Planitia",
		"parcels":"Oxia Palus Unit, Arabia Terra Unit, Chryse Planitia Unit",
		"description":"Oxia Palus is a volcanic plateau in the northern hemisphere of Mars. Arabia Terra is a highland region in the northern hemisphere of Mars. Chryse Planitia is a plain in the northern hemisphere of Mars.",
		"details":"The Oxia Palus Unit is a unit within the Oxia Palus internal zone that is characterized by its smooth, low-lying surface. The Arabia Terra Unit is a unit within the Arabia Terra internal zone that is characterized by its highland features. The Chryse Planitia Unit is a unit within the Chryse Planitia internal zone that is characterized by its smooth, low-lying surface."
	},
	{
		"id":12,
		"cod":"MC-12",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F12-Mars-North-cadastre-2.webp?alt=media&token=bfed31ee-a8c2-4815-bcba-d52e5400fc62&_gl=1*s3jrky*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA3NS40My4wLjA.",
		"hemisphere":"North",
		"title":"MC-12 Arabia",
		"internal":"Arabia Terra, Syrtis Major Planum, Isidis Planitia",
		"parcels":"Arabia Terra Unit, Syrtis Major Planum Unit, Isidis Planitia Unit, Arabia Terra North Unit, Arabia Terra South Unit",
		"description":"Arabia Terra is a highland region in the northern hemisphere of Mars. Syrtis Major Planum is a plain in the northern hemisphere of Mars. Isidis Planitia is a plain in the northern hemisphere of Mars. The Arabia Terra Unit is a unit within the Arabia Terra internal zone that is characterized by its highland features. The Syrtis Major Planum Unit is a unit within the Syrtis Major Planum internal zone that is characterized by its smooth, low-lying surface. The Isidis Planitia Unit is a unit within the Isidis Planitia internal zone that is characterized by its smooth, low-lying surface.",
		"details":"The Arabia Terra North Unit is a unit within the Arabia Terra internal zone that is characterized by its highland features. The Arabia Terra South Unit is a unit within the Arabia Terra internal zone that is characterized by its highland features."
	},
	{
		"id":13,
		"cod":"MC-13",	
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F13-Mars-North-cadastre-2.webp?alt=media&token=ef36ed4a-29d0-4489-b69c-ad89fbed59ad&_gl=1*pj53h6*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA3OC40MC4wLjA.",
		"hemisphere":"North",
		"title":"MC-13 Syrtis Major",
		"internal":"Syrtis Major Planum, Isidis Planitia, Amenthes Planitia",
		"parcels":"Syrtis Major Planum Unit, Isidis Planitia Unit, Amenthes Planitia Unit",
		"description":"Syrtis Major Planum is a dark-hued volcanic plain in the northern hemisphere of Mars. It is one of the most distinctive features on the Martian surface, and is visible from Earth with the naked eye. Isidis Planitia is a large impact basin in the northern hemisphere of Mars. It is one of the largest impact basins in the solar system, and is thought to have formed over 3.5 billion years ago. Amenthes Planitia is a lowland plain in the northern hemisphere of Mars. It is located to the east of Syrtis Major Planum, and is thought to have formed by the outflow of lava from the Tharsis Montes region.",
		"details":"Syrtis Major Planum Unit is a unit within the Syrtis Major Planum internal zone that is characterized by its smooth, low-lying surface. Isidis Planitia Unit is a unit within the Isidis Planitia internal zone that is characterized by its smooth, low-lying surface. Amenthes Planitia Unit is a unit within the Amenthes Planitia internal zone that is characterized by its smooth, low-lying surface."
	},
	{
		"id":14,
		"cod":"MC-14",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F14-Mars-North-cadastre-2.webp?alt=media&token=d08bc3ed-541a-40df-955d-3b3ce0e5876b&_gl=1*22ymli*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA4Mi4zNi4wLjA.",
		"hemisphere":"North",
		"title":"MC-14 Amenthes",
		"internal":"Amenthes Planitia, Arcadia Planitia, Isidis Planitia",
		"parcels":"Amenthes Planitia Unit, Arcadia Planitia Unit, Isidis Planitia Unit",
		"description":"Amenthes Planitia is a lowland plain in the northern hemisphere of Mars. It is located to the east of Syrtis Major Planum, and is thought to have formed by the outflow of lava from the Tharsis Montes region. Arcadia Planitia is a large plain in the Tharsis region of Mars. Isidis Planitia is a large impact basin in the northern hemisphere of Mars. It is one of the largest impact basins in the solar system, and is thought to have formed over 3.5 billion years ago.",
		"details":"Amenthes Planitia Unit is a unit within the Amenthes Planitia internal zone that is characterized by its smooth, low-lying surface. Arcadia Planitia Unit is a unit within the Arcadia Planitia internal zone that is characterized by its smooth, low-lying surface. Isidis Planitia Unit is a unit within the Isidis Planitia internal zone that is characterized by its smooth, low-lying surface."
	},
	{
		"id":15,
		"cod":"MC-15",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F15-Mars-North-cadastre-2.webp?alt=media&token=6cf0b13f-2588-4fdc-9e21-d1aaf5560c74&_gl=1*tmq8m3*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA4OC4zMC4wLjA.",
		"hemisphere":"North",
		"title":"MC-15 Elysium",
		"internal":"Elysium Planitia, Cerberus Fossae, Ceraunius Tholus",
		"parcels":"Elysium Planitia Unit, Cerberus Fossae Unit, Ceraunius Tholus Unit",
		"description":"Elysium Planitia is a vast, lowland plain in the northern hemisphere of Mars. It is located to the east of the Tharsis Montes volcanic region, and is home to three large volcanoes: Elysium Mons, Hecates Tholus, and Albor Tholus. Cerberus Fossae is a system of fissures and troughs in the Elysium Planitia region of Mars. It is thought to have formed by volcanic activity, and is one of the youngest geological features on Mars. Ceraunius Tholus is a shield volcano in the Elysium Planitia region of Mars. It is one of the three large volcanoes in the Elysium region, and is thought to have been active in the past 3 billion years.",
		"details":"Elysium Planitia Unit is a unit within the Elysium Planitia internal zone that is characterized by its smooth, low-lying surface. Cerberus Fossae Unit is a unit within the Cerberus Fossae internal zone that is characterized by its fissures and troughs. Ceraunius Tholus Unit is a unit within the Ceraunius Tholus internal zone that is characterized by its shield volcano."
	},
	{
		"id":16,
		"cod":"MC-16",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F16-Mars-North-cadastre-2.webp?alt=media&token=fd6d6c60-2a8a-4c2f-b656-8951e81f8b15&_gl=1*1r5hbja*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA5Mi4yNi4wLjA.",
		"hemisphere":"North",
		"title":"MC-16 Memnonia",
		"internal":"Memnonia, Elysium Planitia, Ceraunius Tholus",
		"parcels":"Memnonia Unit, Elysium Planitia Unit, Ceraunius Tholus Unit",
		"description":"Memnonia: A region in the northern hemisphere of Mars that is characterized by its low-lying plains and impact craters. Elysium Planitia: A vast, lowland plain in the northern hemisphere of Mars. It is located to the east of the Tharsis Montes volcanic region, and is home to three large volcanoes: Elysium Mons, Hecates Tholus, and Albor Tholus. Ceraunius Tholus: A shield volcano in the Elysium Planitia region of Mars. It is one of the three large volcanoes in the Elysium region, and is thought to have been active in the past 3 billion years.",
		"details":"Memnonia Unit: A unit within the Memnonia internal zone that is characterized by its low-lying plains and impact craters. Elysium Planitia Unit: A unit within the Elysium Planitia internal zone that is characterized by its smooth, low-lying surface. Ceraunius Tholus Unit: A unit within the Ceraunius Tholus internal zone that is characterized by its shield volcano."
	},
	{
		"id":17,
		"cod":"MC-17",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F17-Mars-South-cadastre-1.webp?alt=media&token=e2a6391a-61f6-4110-acd8-ba57367cd3b8&_gl=1*abirl5*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTE5My42MC4wLjA.",
		"hemisphere":"South",
		"title":"MC-17 Phoenicis Lacus",
		"internal":"Phoenicis Lacus, Elysium Planitia, Elysium Mons",
		"parcels":"Phoenicis Lacus Unit, Elysium Planitia Unit, Elysium Mons Unit",
		"description":"Phoenicis Lacus: A large, dried-up lake bed in the northern hemisphere of Mars. It is located to the north of Elysium Mons, and is thought to have been formed by the outflow of water from the Tharsis Montes region. Elysium Planitia: A vast, lowland plain in the northern hemisphere of Mars. It is located to the east of the Tharsis Montes volcanic region, and is home to three large volcanoes: Elysium Mons, Hecates Tholus, and Albor Tholus. Elysium Mons: A massive shield volcano in the Elysium Planitia region of Mars. It is the second largest volcano on Mars, after Olympus Mons.",
		"details":"Phoenicis Lacus Unit: A unit within the Phoenicis Lacus internal zone that is characterized by its dried-up lake bed. Elysium Planitia Unit: A unit within the Elysium Planitia internal zone that is characterized by its smooth, low-lying surface. Elysium Mons Unit: A unit within the Elysium Mons internal zone that is characterized by its shield volcano."
	},
	{
		"id":18,
		"cod":"MC-18",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F18-Mars-South-cadastre-1.webp?alt=media&token=19f97fe7-50c2-4b15-9a83-6f90d40157fd&_gl=1*7a1j9f*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTE5OS41NC4wLjA.",
		"hemisphere":"South",
		"title":"MC-18 Coprates",
		"internal":"Coprates Planitia, Valles Marineris, Tharsis Montes",
		"parcels":"Coprates Planitia Unit, Valles Marineris Unit, Tharsis Montes Unit",
		"description":"Coprates Planitia: A large plain in the Tharsis region of Mars. It is located to the south of Valles Marineris, and is thought to have been formed by the outflow of lava from the Tharsis Montes region. Valles Marineris: A system of canyons in the western hemisphere of Mars. It is the largest canyon system in the solar system, and is over 4,000 kilometers long. Tharsis Montes: A mountain range in the Tharsis region of Mars. It is home to some of the tallest volcanoes in the solar system, including Olympus Mons, Arsia Mons, and Pavonis Mons.",
		"details":"Coprates Planitia Unit: A unit within the Coprates Planitia internal zone that is characterized by its smooth, low-lying surface. Valles Marineris Unit: A unit within the Valles Marineris internal zone that is characterized by its system of canyons. Tharsis Montes Unit: A unit within the Tharsis Montes internal zone that is characterized by its mountain range."
	},
	{
		"id":19,
		"cod":"MC-19",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F19-Mars-South-cadastre-1.webp?alt=media&token=ecac43c6-3146-4439-896f-ee944db42819&_gl=1*rti309*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIwNC40OS4wLjA.",
		"hemisphere":"South",
		"title":"MC-19 Margaritifer Sinus",
		"internal":"Margaritifer Sinus, Valles Marineris, Thaumasia Highlands",
		"parcels":"Margaritifer Sinus Unit, Valles Marineris Unit, Thaumasia Highlands Unit",
		"description":"Margaritifer Sinus: A large impact basin in the southern hemisphere of Mars. It is located to the east of Valles Marineris, and is thought to have been formed by the impact of a large asteroid or comet. Valles Marineris: A system of canyons in the western hemisphere of Mars. It is the largest canyon system in the solar system, and is over 4,000 kilometers long. Thaumasia Highlands: A highland region in the southern hemisphere of Mars. It is located to the west of Margaritifer Sinus, and is thought to be one of the oldest regions on Mars.",
		"details":"Margaritifer Sinus Unit: A unit within the Margaritifer Sinus internal zone that is characterized by its impact basin. Valles Marineris Unit: A unit within the Valles Marineris internal zone that is characterized by its system of canyons. Thaumasia Highlands Unit: A unit within the Thaumasia Highlands internal zone that is characterized by its highland features."
	},
	{
		"id":20,
		"cod":"MC-20",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F20-Mars-South-cadastre-1.webp?alt=media&token=3254c5f1-5495-49e1-b343-4484c7d7a40c&_gl=1*1xh3n0t*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIwOS40NC4wLjA.",
		"hemisphere":"South",
		"title":"MC-20 Sinus Sabaeus",
		"internal":"Sinus Sabaeus, Valles Marineris, Claritas Fossae",
		"parcels":"Sinus Sabaeus Unit, Valles Marineris Unit, Claritas Fossae Unit",
		"description":"Sinus Sabaeus: A large bay in the northern hemisphere of Mars. It is the westernmost of the three large bays in Valles Marineris. Valles Marineris: A system of canyons in the western hemisphere of Mars. It is the largest canyon system in the solar system, and is over 4,000 kilometers long. Claritas Fossae: A system of fissures and troughs in the Valles Marineris region of Mars. It is located to the south of Sinus Sabaeus, and is thought to have formed by volcanic activity.",
		"details":"Sinus Sabaeus Unit: A unit within the Sinus Sabaeus internal zone that is characterized by its bay. Valles Marineris Unit: A unit within the Valles Marineris internal zone that is characterized by its system of canyons. Claritas Fossae Unit: A unit within the Claritas Fossae internal zone that is characterized by its system of fissures and troughs."
	},
	{
		"id":21,
		"cod":"MC-21",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F21-Mars-South-cadastre-1.webp?alt=media&token=f91e81a2-a8d1-4677-8913-1a123adf6257&_gl=1*1kk9xca*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIxNi4zNy4wLjA.",
		"hemisphere":"South",
		"title":"MC-21 Iapygia",
		"internal":"Iapygia, Valles Marineris, Thaumasia Highlands",
		"parcels":"Iapygia Unit, Valles Marineris Unit, Thaumasia Highlands Unit",
		"description":"Iapygia: A large, low-lying region in the southern hemisphere of Mars. It is located to the north of Valles Marineris, and is thought to have been formed by the outflow of water from the Thaumasia Highlands. Valles Marineris: A system of canyons in the western hemisphere of Mars. It is the largest canyon system in the solar system, and is over 4,000 kilometers long. Thaumasia Highlands: A highland region in the southern hemisphere of Mars. It is located to the south of Iapygia, and is thought to be one of the oldest regions on Mars.",
		"details":"Iapygia Unit: A unit within the Iapygia internal zone that is characterized by its low-lying surface. Valles Marineris Unit: A unit within the Valles Marineris internal zone that is characterized by its system of canyons. Thaumasia Highlands Unit: A unit within the Thaumasia Highlands internal zone that is characterized by its highland features."
	},
	{
		"id":22,
		"cod":"MC-22",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F22-Mars-South-cadastre-1.webp?alt=media&token=ef07f5db-0b37-4d35-b07e-276321fadded&_gl=1*aogoi8*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIyMS4zMi4wLjA.",
		"hemisphere":"South",
		"title":"MC-22 Mare Tyrrhenum",
		"internal":"Mare Tyrrhenum, Hellas Planitia, Thaumasia Highlands",
		"parcels":"Mare Tyrrhenum Unit, Hellas Planitia Unit, Thaumasia Highlands Unit",
		"description":"Mare Tyrrhenum: A large basin in the southern hemisphere of Mars. It is located to the northwest of Hellas Planitia, and is thought to have formed by the impact of a large asteroid or comet. Hellas Planitia: A large impact basin in the southern hemisphere of Mars. It is the largest impact basin in the solar system, and is over 6,000 kilometers wide. Thaumasia Highlands: A highland region in the southern hemisphere of Mars. It is located to the east of Mare Tyrrhenum, and is thought to be one of the oldest regions on Mars.",
		"details":"Mare Tyrrhenum Unit: A unit within the Mare Tyrrhenum internal zone that is characterized by its impact basin. Hellas Planitia Unit: A unit within the Hellas Planitia internal zone that is characterized by its impact basin. Thaumasia Highlands Unit: A unit within the Thaumasia Highlands internal zone that is characterized by its highland features."
	},
	{
		"id":23,
		"cod":"MC-23",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F23-Mars-South-cadastre-1.webp?alt=media&token=816a419e-5b3c-4585-8162-0ffe160978d3&_gl=1*1q1szge*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIyOC4yNS4wLjA.",
		"hemisphere":"South",
		"title":"MC-23 Aeolis",
		"internal":"Aeolis Mons, Gale Crater, Aeolis Planum",
		"parcels":"Aeolis Mons Unit, Gale Crater Unit, Aeolis Planum Unit",
		"description":"Aeolis Mons: A shield volcano in the Aeolis quadrangle of Mars. It is the central peak of Gale Crater, and is thought to have been active billions of years ago. Gale Crater: A large impact crater in the Aeolis quadrangle of Mars. It is home to the Curiosity rover, which has been exploring the crater since 2012. Aeolis Planum: A plain in the Aeolis quadrangle of Mars. It is located to the north of Gale Crater, and is thought to have been formed by the outflow of lava from Aeolis Mons.",
		"details":"Aeolis Mons Unit: A unit within the Aeolis Mons internal zone that is characterized by its shield volcano. Gale Crater Unit: A unit within the Gale Crater internal zone that is characterized by its impact crater. Aeolis Planum Unit: A unit within the Aeolis Planum internal zone that is characterized by its plain."
	},
	{
		"id":24,
		"cod":"MC-24",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F24-Mars-South-cadastre-1.webp?alt=media&token=a242cd02-1091-4d4e-adc8-f0a8d84866b3&_gl=1*1c7ps4a*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIzMy4yMC4wLjA.",
		"hemisphere":"South",
		"title":"MC-24 Phaethontis",
		"internal":"Terra Sirenum, Tharsis Montes, Tharsis Planitia",
		"parcels":"Terra Sirenum Unit, Tharsis Montes Unit, Tharsis Planitia Unit, Atlantis Chaos Unit, Gorgonum Chaos Unit, Mariner Crater Unit",
		"description":"Terra Sirenum: A heavily cratered highland region in the Phaethontis quadrangle of Mars. It is located to the east of Tharsis Montes, and is thought to be one of the oldest regions on Mars. Tharsis Montes: A mountain range in the Phaethontis quadrangle of Mars. It is home to some of the tallest volcanoes in the solar system, including Olympus Mons, Arsia Mons, and Pavonis Mons. Tharsis Planitia: A plain in the Phaethontis quadrangle of Mars. It is located to the west of Tharsis Montes, and is thought to have been formed by the outflow of lava from the volcanoes in the Tharsis Montes region. Atlantis Chaos Unit: A unit within the Terra Sirenum internal zone that is characterized by its chaotic terrain. Gorgonum Chaos Unit: A unit within the Terra Sirenum internal zone that is characterized by its chaotic terrain. Mariner Crater Unit: A unit within the Terra Sirenum internal zone that is characterized by its impact crater.", 
		"details":""
	},
	{
		"id":25,
		"cod":"MC-25",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F25-Mars-South-cadastre-2.webp?alt=media&token=977bf3d0-34cf-48b8-afd6-ab7703443afa&_gl=1*1g83j7a*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIzOS4xNC4wLjA.",
		"hemisphere":"South",
		"title":"MC-25 Thaumasia",
		"internal":"Thaumasia Highlands, Valles Marineris, Margaritifer Sinus",
		"parcels":"Thaumasia Highlands Unit, Valles Marineris Unit, Margaritifer Sinus Unit",
		"description":"Thaumasia Highlands: A highland region in the southern hemisphere of Mars. It is one of the oldest regions on Mars, and is thought to have formed by the impact of a large asteroid or comet. Valles Marineris: A system of canyons in the western hemisphere of Mars. It is the largest canyon system in the solar system, and is over 4,000 kilometers long. Margaritifer Sinus: A large impact basin in the southern hemisphere of Mars. It is located to the east of Valles Marineris, and is thought to have been formed by the impact of a large asteroid or comet.",
		"details":"Thaumasia Highlands Unit: A unit within the Thaumasia Highlands internal zone that is characterized by its highland features. Valles Marineris Unit: A unit within the Valles Marineris internal zone that is characterized by its system of canyons. Margaritifer Sinus Unit: A unit within the Margaritifer Sinus internal zone that is characterized by its impact basin."
	},
	{
		"id":26,
		"cod":"MC-26",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F26-Mars-South-cadastre-2.webp?alt=media&token=6626b8b1-61ff-4e29-ae8d-fa414474584b&_gl=1*1kv6zp9*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTI0Ny42LjAuMA..",
		"hemisphere":"South",
		"title":"MC-26 Argyre",
		"internal":"Argyre Planitia, Noachis Terra, Hellas Planitia",
		"parcels":"Argyre Planitia Unit, Noachis Terra Unit, Hellas Planitia Unit",
		"description":"Argyre Planitia: A large impact basin in the southern hemisphere of Mars, located to the west of Noachis Terra. It is one of the oldest and largest impact basins on Mars, and is thought to have formed over 4 billion years ago. Noachis Terra: A heavily cratered highland region in the southern hemisphere of Mars, located to the east of Argyre Planitia. It is one of the oldest regions on Mars, and is thought to have formed early in the planet's history. Hellas Planitia: A large impact basin in the southern hemisphere of Mars, located to the south of Argyre Planitia. It is the largest impact basin in the solar system, and is over 6,000 kilometers wide.",
		"details":"Argyre Planitia Unit: A unit within the Argyre Planitia internal zone that is characterized by its impact basin. Noachis Terra Unit: A unit within the Noachis Terra internal zone that is characterized by its heavily cratered highland features. Hellas Planitia Unit: A unit within the Hellas Planitia internal zone that is characterized by its impact basin."
	},
	{
		"id":27,
		"cod":"MC-27",	
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F27-Mars-South-cadastre-2.webp?alt=media&token=20192d6b-0af8-4451-85ec-0c420f938ac7&_gl=1*1nwe6ny*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM1MC41Ny4wLjA.",
		"hemisphere":"South",
		"title":"MC-27 Noachis",
		"internal":"Noachis Terra, Hellas Planitia, Valles Marineris",
		"parcels":"Noachis Terra Unit, Hellas Planitia Unit, Valles Marineris Unit",
		"description":"Noachis Terra: A heavily cratered highland region in the southern hemisphere of Mars, located to the east of Argyre Planitia and west of Hellas Planitia. It is one of the oldest regions on Mars, and is thought to have formed early in the planet's history. Hellas Planitia: A large impact basin in the southern hemisphere of Mars, located to the south of Noachis Terra. It is the largest impact basin in the solar system, and is over 6,000 kilometers wide. Valles Marineris: A system of canyons in the western hemisphere of Mars, located to the north of Noachis Terra. It is the largest canyon system in the solar system, and is over 4,000 kilometers long.",
		"details":"Noachis Terra Unit: A unit within the Noachis Terra internal zone that is characterized by its heavily cratered highland features. Hellas Planitia Unit: A unit within the Hellas Planitia internal zone that is characterized by its impact basin. Valles Marineris Unit: A unit within the Valles Marineris internal zone that is characterized by its system of canyons."
	},
	{
		"id":28,
		"cod":"MC-28",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F28-Mars-South-cadastre-2.webp?alt=media&token=aad0b2e5-4e14-47fa-b363-276e3cd46473&_gl=1*965eb0*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM1Ni41MS4wLjA.",
		"hemisphere":"South",
		"title":"MC-28 Hellas",
		"internal":"Hellas Planitia, Promethei Terra, Hadriaca Patera",
		"parcels":"Hellas Planitia Unit, Promethei Terra Unit, Hadriaca Patera Unit",
		"description":"Hellas Planitia: A large impact basin in the southern hemisphere of Mars, located to the west of Promethei Terra. It is the largest impact basin in the solar system, and is over 6,000 kilometers wide. Promethei Terra: A heavily cratered highland region in the southern hemisphere of Mars, located to the east of Hellas Planitia. It is one of the oldest regions on Mars, and is thought to have formed early in the planet's history. Hadriaca Patera: A shield volcano in the southern hemisphere of Mars, located to the north of Hellas Planitia. It is one of the largest volcanoes on Mars, and is thought to have been active billions of years ago.",
		"details":"Hellas Planitia Unit: A unit within the Hellas Planitia internal zone that is characterized by its impact basin. Promethei Terra Unit: A unit within the Promethei Terra internal zone that is characterized by its heavily cratered highland features. Hadriaca Patera Unit: A unit within the Hadriaca Patera internal zone that is characterized by its shield volcano."
	},
	{
		"id":29,
		"cod":"MC-29",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F29-Mars-South-cadastre-2.webp?alt=media&token=7668b8e0-cd5c-4dff-a147-a805aa3b1fff&_gl=1*1e2rw6u*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM2MS40Ni4wLjA.",
		"hemisphere":"South",
		"title":"MC-29 Eridania",
		"internal":"Eridania Planitia, Arcadia Planitia, Isidis Planitia",
		"parcels":"Eridania Planitia Unit, Arcadia Planitia Unit, Isidis Planitia Unit",
		"description":"Eridania Planitia: A large lowland plain in the northern hemisphere of Mars, located to the east of Arcadia Planitia and west of Isidis Planitia. It is thought to have formed by the outflow of lava from the Tharsis Montes region. Arcadia Planitia: A large plain in the Tharsis region of Mars, located to the east of Eridania Planitia and west of Isidis Planitia. It is home to three large volcanoes: Elysium Mons, Hecates Tholus, and Albor Tholus. Isidis Planitia: A large impact basin in the northern hemisphere of Mars, located to the west of Eridania Planitia and east of Arcadia Planitia. It is one of the largest impact basins in the solar system, and is thought to have formed over 3.5 billion years ago.",
		"details":"Eridania Planitia Unit: A unit within the Eridania Planitia internal zone that is characterized by its smooth, low-lying surface. Arcadia Planitia Unit: A unit within the Arcadia Planitia internal zone that is characterized by its smooth, low-lying surface. Isidis Planitia Unit: A unit within the Isidis Planitia internal zone that is characterized by its smooth, low-lying surface."
	},
	{
		"id":30,
		"cod":"MC-30",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F30-Mars-South-cadastre-2.webp?alt=media&token=acc5de76-5284-489d-8c06-00bc50581023&_gl=1*12rrrxd*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM2Ni40MS4wLjA.",
		"hemisphere":"South",
		"title":"MC-30 Mare Australe",
		"internal":"Mare Australe, Planum Australe, Ultimum Mons",
		"parcels":"Mare Australe Unit, Planum Australe Unit, Ultimum Mons Unit",
		"description":"Mare Australe: A large impact basin in the southern hemisphere of Mars, located near the south pole. It is one of the largest impact basins on Mars, and is thought to have formed over 4 billion years ago. Planum Australe: A large polar plateau in the southern hemisphere of Mars, located within Mare Australe. It is one of the highest regions on Mars, and is thought to be made up of water ice and frozen carbon dioxide. Ultimum Mons: A shield volcano in the southern hemisphere of Mars, located to the north of Mare Australe. It is one of the largest volcanoes on Mars, and is thought to have been active billions of years ago.",
		"details":"Mare Australe Unit: A unit within the Mare Australe internal zone that is characterized by its impact basin. Planum Australe Unit: A unit within the Planum Australe internal zone that is characterized by its polar plateau. Ultimum Mons Unit: A unit within the Ultimum Mons internal zone that is characterized by its shield volcano."
	},
	{
		"id":31,
		"cod":"MC-31",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F31-Mars-South-cadastre-2.webp?alt=media&token=b8fd8b58-6031-47ac-ad4a-092827f9b7e0&_gl=1*cocmcr*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM3My4zNC4wLjA.",
		"hemisphere":"South",
		"title":"",
		"internal":"",
		"parcels":"",
		"description":"",
		"details":""
	},
	{
		"id":32,
		"cod":"MC-32",
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F32-Mars-South-cadastre-2.webp?alt=media&token=11eb98ab-39f0-4030-ba15-04f049550170&_gl=1*16mv54p*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM3OC4yOS4wLjA.",
		"hemisphere":"South",
		"title":"",
		"internal":"",
		"parcels":"",
		"description":"",
		"details":""
	}
]