export const cadastre1 = [
	{
		"id":1,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F01-Mars-North-cadastre-1.webp?alt=media&token=66a83acb-eee7-43e4-99a7-4d8d5393d019&_gl=1*19f6dg7*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkxNi42MC4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":2,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F02-Mars-North-cadastre-1.webp?alt=media&token=f88128b2-6d47-4337-adc9-d95324441d7d&_gl=1*59g4h6*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkyMi41NC4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":3,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F03-Mars-North-cadastre-1.webp?alt=media&token=a11e3294-78c0-4afd-92e7-382453f184f9&_gl=1*1tgcddl*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkyNi41MC4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":4,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F04-Mars-North-cadastre-1.webp?alt=media&token=8bf6e5cc-1c32-4721-9bc3-1a0c8a07586e&_gl=1*1ez50va*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkzMC40Ni4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":5,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F05-Mars-North-cadastre-1.webp?alt=media&token=96e393d6-3e52-4314-88ea-ee7795e98609&_gl=1*ltq4s5*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkzNC40Mi4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":6,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F06-Mars-North-cadastre-1.webp?alt=media&token=f6710eff-88d1-4ff9-b7fb-706540883108&_gl=1*1evemek*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDkzOC4zOC4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":7,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F07-Mars-North-cadastre-1.webp?alt=media&token=5e5c4539-2062-4f7d-aa9d-9673c96ca8fb&_gl=1*w89j5f*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDk0Mi4zNC4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":8,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F08-Mars-North-cadastre-1.webp?alt=media&token=96c1c9e0-2f46-4078-a4d0-ef1aa329bda3&_gl=1*136njfk*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNDk0Ni4zMC4wLjA.",
		"hemisphere":"North"
	}
]

export const cadastre2 = [
	{
		"id":9,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F09-Mars-North-cadastre-2.webp?alt=media&token=b26f2257-ce2a-4c7c-a2a1-3a28e674f068&_gl=1*1xtjobl*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA2Mi41Ni4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":10,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F10-Mars-North-cadastre-2.webp?alt=media&token=1756ba0f-a182-4bd7-85e0-7453a4fad2c5&_gl=1*18hm2w5*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA2Ny41MS4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":11,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F11-Mars-North-cadastre-2.webp?alt=media&token=2691b21c-836b-447d-82c2-be208986875d&_gl=1*17dkbjl*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA3MS40Ny4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":12,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F12-Mars-North-cadastre-2.webp?alt=media&token=bfed31ee-a8c2-4815-bcba-d52e5400fc62&_gl=1*s3jrky*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA3NS40My4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":13,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F13-Mars-North-cadastre-2.webp?alt=media&token=ef36ed4a-29d0-4489-b69c-ad89fbed59ad&_gl=1*pj53h6*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA3OC40MC4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":14,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F14-Mars-North-cadastre-2.webp?alt=media&token=d08bc3ed-541a-40df-955d-3b3ce0e5876b&_gl=1*22ymli*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA4Mi4zNi4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":15,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F15-Mars-North-cadastre-2.webp?alt=media&token=6cf0b13f-2588-4fdc-9e21-d1aaf5560c74&_gl=1*tmq8m3*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA4OC4zMC4wLjA.",
		"hemisphere":"North"
	},
	{
		"id":16,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F16-Mars-North-cadastre-2.webp?alt=media&token=fd6d6c60-2a8a-4c2f-b656-8951e81f8b15&_gl=1*1r5hbja*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTA5Mi4yNi4wLjA.",
		"hemisphere":"North"
	}
]

export const cadastre3 = [
	{
		"id":17,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F17-Mars-South-cadastre-1.webp?alt=media&token=e2a6391a-61f6-4110-acd8-ba57367cd3b8&_gl=1*abirl5*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTE5My42MC4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":18,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F18-Mars-South-cadastre-1.webp?alt=media&token=19f97fe7-50c2-4b15-9a83-6f90d40157fd&_gl=1*7a1j9f*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTE5OS41NC4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":19,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F19-Mars-South-cadastre-1.webp?alt=media&token=ecac43c6-3146-4439-896f-ee944db42819&_gl=1*rti309*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIwNC40OS4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":20,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F20-Mars-South-cadastre-1.webp?alt=media&token=3254c5f1-5495-49e1-b343-4484c7d7a40c&_gl=1*1xh3n0t*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIwOS40NC4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":21,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F21-Mars-South-cadastre-1.webp?alt=media&token=f91e81a2-a8d1-4677-8913-1a123adf6257&_gl=1*1kk9xca*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIxNi4zNy4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":22,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F22-Mars-South-cadastre-1.webp?alt=media&token=ef07f5db-0b37-4d35-b07e-276321fadded&_gl=1*aogoi8*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIyMS4zMi4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":23,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F23-Mars-South-cadastre-1.webp?alt=media&token=816a419e-5b3c-4585-8162-0ffe160978d3&_gl=1*1q1szge*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIyOC4yNS4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":24,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F24-Mars-South-cadastre-1.webp?alt=media&token=a242cd02-1091-4d4e-adc8-f0a8d84866b3&_gl=1*1c7ps4a*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIzMy4yMC4wLjA.",
		"hemisphere":"South"
	}
]

export const cadastre4 = [
	{
		"id":25,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F25-Mars-South-cadastre-2.webp?alt=media&token=977bf3d0-34cf-48b8-afd6-ab7703443afa&_gl=1*1g83j7a*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTIzOS4xNC4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":26,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F26-Mars-South-cadastre-2.webp?alt=media&token=6626b8b1-61ff-4e29-ae8d-fa414474584b&_gl=1*1kv6zp9*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTI0Ny42LjAuMA..",
		"hemisphere":"South"
	},
	{
		"id":27,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F27-Mars-South-cadastre-2.webp?alt=media&token=20192d6b-0af8-4451-85ec-0c420f938ac7&_gl=1*1nwe6ny*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM1MC41Ny4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":28,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F28-Mars-South-cadastre-2.webp?alt=media&token=aad0b2e5-4e14-47fa-b363-276e3cd46473&_gl=1*965eb0*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM1Ni41MS4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":29,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F29-Mars-South-cadastre-2.webp?alt=media&token=7668b8e0-cd5c-4dff-a147-a805aa3b1fff&_gl=1*1e2rw6u*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM2MS40Ni4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":30,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F30-Mars-South-cadastre-2.webp?alt=media&token=acc5de76-5284-489d-8c06-00bc50581023&_gl=1*12rrrxd*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM2Ni40MS4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":31,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F31-Mars-South-cadastre-2.webp?alt=media&token=b8fd8b58-6031-47ac-ad4a-092827f9b7e0&_gl=1*cocmcr*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM3My4zNC4wLjA.",
		"hemisphere":"South"
	},
	{
		"id":32,
		"urlImage":"https://firebasestorage.googleapis.com/v0/b/redground-proto.appspot.com/o/resources%2Fcadastre%2F32-Mars-South-cadastre-2.webp?alt=media&token=11eb98ab-39f0-4030-ba15-04f049550170&_gl=1*16mv54p*_ga*MTI2Mzg5MzQ5Mi4xNjk4MTg3NDIx*_ga_CW55HF8NVT*MTY5ODIxNDAyNi4zLjEuMTY5ODIxNTM3OC4yOS4wLjA.",
		"hemisphere":"South"
	}
]