import React, {useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import "./TokenScreen.css";
import { CadastreArrayContext } from '../context/CadastreArrayContext.js';
import GoToTop from "../GoToTop.js";
import firebaseApp from "../firebase";
import { getAuth } from "firebase/auth";
import arrowLeft from "../assets/arrow-left.svg";

function TokenScreen() {
  const auth = getAuth(firebaseApp);
  console.log(auth);
  const [cadastreArray, setCadastreArray] = useContext(CadastreArrayContext);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [tokenCod, setTokenCod] = useState(cadastreArray[0]?.cod+"RDGRND"+Date.now());
  console.log(cadastreArray);

  const navigate = useNavigate();

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity-1)
    }
  }

  const handleIncrement = () => {
    if (quantity < 12) {
      setQuantity(quantity+1)
    }
  }

  const TokenCreation = async () => {
    const apiUrl = 'http://192.168.18.8:5000/api/postdata';

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          regionCod:cadastreArray[0]?.cod,
          tokenCod:tokenCod,
          title:cadastreArray[0]?.title,
          hemisphere:cadastreArray[0]?.hemisphere,
          price:cadastreArray[1],
          firstname:firstname,
          lastname:lastname,
          email:email,
          address:address,
          phonenumber:phonenumber,
          useruid:auth.currentUser.uid,
          username:(auth.currentUser.email).split('@')[0],
          fecha: Date.now(),
          timestamp: (new Date()).getTime(),
      }),
      });
      const responseData = await response.json();
      console.log('Data posted:', responseData);
    } catch (error) {
      console.error('Error posting data:', error);
    }
  };


  return (
    <div key={cadastreArray.id} className='TokenScreen' >
      <div className='TokenScreen__return' onClick={()=> {navigate("/mars")}}>
        <img
          src={arrowLeft}
          alt=''
        />
        <p>return to Mars</p>
      </div>

      <div className='TokenScreen__mainmsg'>
        <h1>You are getting your own piece of Mars.</h1>
      </div>

      <div className='TokenScreen__level1' >
        <div className='TokenScreen__level1__image'>
          <img
            src={cadastreArray[0]?.urlImage}
            alt={cadastreArray[0]?.title}
          />
        </div>
        <div className='TokenScreen__level1__data'>
          <h2>{cadastreArray[0]?.title} • {cadastreArray[0]?.hemisphere}</h2>
          <h3>{cadastreArray[0]?.internal}</h3>
          <p>{cadastreArray[0]?.parcels}</p>

          <div className='TokenScreen__level1__data__quantity'>
            <h3>US$. {(parseInt(cadastreArray[1])*quantity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} per Square Kilometer</h3>

            <div className='TokenScreen__level1__data__quantity__buttons'>
              <button type="button" onClick={handleDecrement}>-</button>

              <p>{quantity}</p>

              <button type="button" onClick={handleIncrement}>+</button>
            </div>

            <h4>Your token ticket number is: {tokenCod}</h4>
          </div>
        </div>
      </div>

      <div className='TokenScreen__level2'>
      <p>{cadastreArray[0]?.description}</p>
      <p>{cadastreArray[0]?.details}</p>
      </div>

      <div className='TokenScreen__level3'>
        <p>Choose your favorite payment gateway:</p>
        <div>
          <button>Paypal</button>
          <button>Google Pay</button>
          <button>Coinbase</button>
        </div>
      </div>

      <div >
        <h2>Share a few details about you to generate your own NFT:</h2>

        <form >
        <ul>
            <li>
            <input type="text" placeholder='Firstname' value={firstname} onChange={(e) => setFirstname(e.target.value)} />
            </li>

            <li>
            <input type="text" placeholder='Lastname' value={lastname} onChange={(e) => setLastname(e.target.value)} />
            </li>

            <li>
            <input type="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </li>

            <li>
            <input type="text" placeholder='Address' value={address} onChange={(e) => setAddress(e.target.value)} />
            </li>

            <li>
            <input type="text" placeholder='Phone number' value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} />
            </li>

        </ul>
        </form>

        <div className='TokenScreen__send'>
          <button
              type='submit' 
              onClick={TokenCreation} >Get my NFT for {tokenCod}
          </button>
        </div>
      </div>

      <div>
        <p>2023 redground inc. :: all rights reserved.</p>
      </div>

      <GoToTop/>
       
    </div>
  )
}

export default TokenScreen;