import React from 'react';
import "./Copyright.css";

function Copyright() {
    return (
        
    <div className="copyright">
        <p>&#123; redground 2023 &#9679; Adjudicate Now a Piece of Mars.&#x7D;</p>
    </div>
        
    )
}

export default Copyright;
