import React, { useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./SignInScreen.css";
import "./NewUserScreen.css";
import Copyright from "../../components/Footer/Copyright";
import { auth } from "../../firebase.js";
import GoToTop from '../../GoToTop';
/* import {Helmet} from "react-helmet";
import Head from "next/head"; */
import Reel from "../../assets/mars.webm";
import logo from "../../assets/Redground-oldlogo.png";

function SignInScreen() {
    const [ status, setStatus ] = useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const navigate = useNavigate();

    const register = (e) => {
        e.preventDefault();

        auth
            .createUserWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                navigate("/mars")
            })
            .catch((error) => {
                alert(error.message);
            });
    }

    const gotoSignin = (e) => {
        e.preventDefault();
        setStatus(true);
        /* history.push("/signin"); */
    }

    const signIn = (e) => {
        e.preventDefault();

        auth
            .signInWithEmailAndPassword(
                emailRef.current.value,
                passwordRef.current.value
            )
            .then((authUser) => {
                console.log(authUser);
            })
            .catch((error) => {
                alert(error.message);
            });
        
        navigate("/mars");
    };

    const gotoSignup = (e) => {
        e.preventDefault();
        setStatus(false);
        /* history.push("/signup"); */
    }

    const gotoReset = (e) => {
        e.preventDefault();
        navigate("/reset");
    }

    const gotoHome = (e) => {
        e.preventDefault();
        navigate("/");
    }

  return (
    <main>

        {/* <Head>
            <title></title>
            <meta 
            name='description'
            content=""
            />
        </Head>

        <Helmet>
            <title></title>
            <meta 
            name='description'
            content=""
            />
            <meta 
            name='image' 
            content=""
            />
        </Helmet> */}


        <header className="signin__banner">

            <video autoPlay loop muted
                style={{
                    position: "absolute",
                    zIndex: "-1",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    backgroundPosition: "center center",
                    backgroundImage: `linear-gradient(to top, black 10%, transparent 90%), linear-gradient(to right, #00001b, transparent)`,
                }}>
                <source src={Reel} type='video/webm' />
            </video>

            {status
            ? (
            <div className="signin__signin">

            <div className="signin__signin__msg">
                <p>Welcome back to </p>
                <img
                    src={logo}
                    alt=""
                    onClick={gotoHome}
                />
            </div>

            <form >
                <input  ref={emailRef} type="email" placeholder="email" id="email" />

                <input ref={passwordRef} type="Password" placeholder="password" id="password" />

                <button 
                type="submit" onClick={signIn}>
                    Log In
                </button>
            </form>

            <div className="signin__signin__help">
                <div className="signin__help__signin">
                    <p>Forgot your password?</p>
                    <span
                    onClick={gotoReset}
                    >
                    Reset it here.
                    </span>
                </div>

                <div className="signin__help__signin">
                    <p >Are you new here?</p>
                    <span 
                    onClick={gotoSignup}
                    >Create a free account here.</span>
                </div>
            </div>

            </div>
            )
            : (
            <div className="newuser__signup">

            <div className="newuser__signup__msg">
                <p>Welcome to</p>
                <img
                    src={logo}
                    alt=""
                    onClick={gotoHome}
                />
            </div>

            <form >
                <input  ref={emailRef} type="email" placeholder="email" id="email" />

                <input ref={passwordRef} type="Password" placeholder="password" id="password" />

                <button 
                type="submit" onClick={register}>
                    Create account
                </button>
            </form>

            <div className="newuser__signup__help">
            <p >Have an account already?</p>
            <span
                onClick={gotoSignin}
            >Log In Here.</span>
            </div>

            </div>
            )}

            
            
        </header>
        
        <Copyright />
        <GoToTop />
    </main>
  )
}

export default SignInScreen;