import React, { /* useRef,  */useState, useEffect, useContext } from "react";
import { CadastreArrayContext } from '../context/CadastreArrayContext.js';
import './HomeScreen.css';
import  firebaseApp from "../firebase";
import "firebase/compat/firestore";
import { getAuth/* , onAuthStateChanged */ } from "firebase/auth";
import logo from "../assets/Redground-oldlogo.png";
import { useNavigate } from "react-router-dom";
import { cadastre1, cadastre2, cadastre3, cadastre4 } from "../assets/cadastre.js";
import { cadastreAll } from "../assets/cadastreAll.js";
import GoToTop from "../GoToTop.js";

const auth = getAuth(firebaseApp);


function HomeScreen() {
  const [cadastreId, setCadastreId] = useState(0);
  const [cadastreStatus, setCadastreStatus] = useState(false);
  const [cadastreCounter, setCadastreCounter] = useState(0);
  const [cadastrePrice, setCadastrePrice] = useState("");
  const [cadastreArray, setCadastreArray] = useContext(CadastreArrayContext);
  const navigate = useNavigate();

  useEffect(() => {
    function generateRandomIntegerInRange(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    let usersconnected = generateRandomIntegerInRange(1500, 1800)
  
    setCadastrePrice(usersconnected/* .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") */);
    
  }, [cadastreCounter])

  const handleCadastreId = (e) => {
    setCadastreId(parseInt(e.target.id));
    setCadastreStatus(true);
    setCadastreCounter(parseInt(cadastreCounter)+1);
  };

  const handleToken = (e) => {
    const arrayX = cadastreAll.filter((zone) =>
    parseInt(zone?.id) === parseInt(e.target.id)
    );
    arrayX.push(cadastrePrice);
    setCadastreArray(arrayX);
    navigate("/token");
  }

  return (
    <div className="Home" >

      <div className="Home__Cadastre__logo">
        <img
          src={logo}
          alt=''
        />
      </div>

      <div className="Home__Cadastre">

        <div className="Cadastre" >
        {cadastre1.map((zone) => (
            <div key={zone?.id} className="Cadastre__container">
                <img
                    className="Cadastre__imgZone"
                    src={zone?.urlImage}
                    alt={zone?.hemisphere}
                    title={zone?.hemisphere}
                    id={zone?.id}
                    onClick={handleCadastreId}
                />
            </div>
        ))
        }
        </div>

        <div className="Cadastre" >
        {cadastre2.map((zone) => (
            <div key={zone?.id} className="Cadastre__container">
                <img
                    className="Cadastre__imgZone"
                    src={zone?.urlImage}
                    alt={zone?.hemisphere}
                    title={zone?.hemisphere}
                    id={zone?.id}
                    onClick={handleCadastreId}
                />
            </div>
        ))
        }
        </div>

        <div className="Cadastre" >
        {cadastre3.map((zone) => (
            <div key={zone?.id} className="Cadastre__container">
                <img
                    className="Cadastre__imgZone"
                    src={zone?.urlImage}
                    alt={zone?.hemisphere}
                    title={zone?.hemisphere}
                    id={zone?.id}
                    onClick={handleCadastreId}
                />
            </div>
        ))
        }
        </div>

        <div className="Cadastre" >
        {cadastre4.map((zone) => (
            <div key={zone?.id} className="Cadastre__container">
                <img
                    className="Cadastre__imgZone"
                    src={zone?.urlImage}
                    alt={zone?.hemisphere}
                    title={zone?.hemisphere}
                    id={zone?.id}
                    onClick={handleCadastreId}
                />
            </div>
        ))
        }
        </div>
      </div>
      
      {cadastreStatus &&
      <div>
      {cadastreAll.filter((zone) =>
        parseInt(zone?.id) === parseInt(cadastreId)
      ).map((zone) => (

      <div className="Cadastre__popup" key={zone.id} >
        <div className="Cadastre__popup__content">
          <h1>{zone?.title}</h1>
          <h3>US$ {cadastrePrice} per km2 by Nov. 01 2023</h3>
          <img
            src={zone?.urlImage}
            alt={zone?.hemisphere}
          />
          <p>{zone?.internal}</p>
          <p>{zone?.description}</p>
          <button id={zone.id} onClick={handleToken} >Adjudicate it</button>
        </div>  
      </div>
      ))
      }
      </div>
      }

      <a
      style={{ cursor:"pointer", margin:"20px 0" }} 
      onClick={() => {
      auth.signOut();
      navigate("/");
      }}>
      Log out from Redground</a>

      <GoToTop/>

    </div>
  );
}

export default HomeScreen;
